import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { KIOSK_AUTO_LOGIN, setIntervalTimeOut  } from 'src/app/common/api';
import { LocalStoreService } from 'src/app/services/localstore.service';

@Component({
  selector: 'app-cancel-payment',
  templateUrl: './cancel-payment.page.html',
  styleUrls: ['./cancel-payment.page.scss'],
})
export class CancelPaymentPage implements OnInit {
  time_left_counter : any;
  time_left = 0;
  time_stop = false;
  show_cancel_button = false;
  mid_cancel = false;
  constructor(
    public alertController: AlertController,
    public modalController: ModalController,
    private store:LocalStoreService,
    private router: Router
  ) { }

  ngOnInit() {
    this.time_stop = false;
    this.time_left = 30; 
    this.time_left_counter = setInterval(()=>{
    this.time_left -= 1;
    if (this.time_left == 0) {
      clearInterval(this.time_left_counter);
      let Autologin = KIOSK_AUTO_LOGIN
      let terminalid = this.store.get('terminalid')
      this.store.hardlogout()
      location.href=Autologin + '/' + terminalid ;
      this.modalController.dismiss();
    return false;
    } 
    }, setIntervalTimeOut);
  }

  dismiss(){
    this.modalController.dismiss();
    clearInterval(this.time_left_counter);
    let Autologin = KIOSK_AUTO_LOGIN
    let terminalid = this.store.get('terminalid')
    this.store.hardlogout()
    location.href=Autologin + '/' + terminalid ;
   
  }

}
